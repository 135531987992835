import React from "react";
import { Col, Container, Row } from "reactstrap";
import no1 from "../../assets/images/telkom/no1.png";
import no2 from "../../assets/images/telkom/no2.png";
import no3 from "../../assets/images/telkom/no3.png";
import no4 from "../../assets/images/telkom/no4.png";
import no5 from "../../assets/images/telkom/no5.png";
import no6 from "../../assets/images/telkom/no6.png";


const P2 = () => {
  document.title="Dashboard | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <h3>Tata Cara Pemakaian</h3>
        <div style={{display: 'flex', alignItems: 'center' }}>
          <img src={no1}/>
          <p style={{marginBottom: '8px'}}>Baca pertanyaan dan arahan jawaban pada sesi simulasi interview pada page setelah ini.</p>
        </div>
        <div style={{display: 'flex', alignItems: 'center' }}>
          <img src={no2}/>
          <p style={{marginBottom: '8px'}}>Coba berlatih terlebih dahulu jawaban dari interviewnya, sebelum mulai merekamnya.</p>
        </div>
        <div style={{display: 'flex', alignItems: 'center' }}>
          <img src={no3}/>
          <p style={{marginBottom: '8px'}}>Klik tombol "Preview" untuk melihat hasil rekaman sesi simulasi interview Anda.</p>
        </div>
        <div style={{display: 'flex', alignItems: 'center' }}>
          <img src={no4}/>
          <p style={{marginBottom: '8px'}}>Setelah Anda telah berhasil menyelesaikan sesi simulasi tanpa kendala, silahkan mulai sesi interview Anda dengan klik tombol "Mulai".</p>
        </div>
        <div style={{display: 'flex', alignItems: 'center' }}>
          <img src={no5}/>
          <p style={{marginBottom: '8px'}}>Setelah Anda telah selesai melihat video sambutan dari interviewer,silahkan klik tombol "Selanjutnya".</p>
        </div>
        <div style={{display: 'flex', alignItems: 'center' }}>
          <img src={no6}/>
          <p style={{marginBottom: '8px'}}>Setelah memperhatikan pertanyaan dan waktu enjambment, silakan klik tombol “Mulai”. Jika Anda telah selesai menjawab, silakan klik tombol “Kirim”. Jika Anda telah yakin dengan semua jawaban Anda, silakan klik tombol “Submit”</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default P2;
