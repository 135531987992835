import React, { useState } from "react";
import { Col, Container, Row, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Label, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, } from "reactstrap";
import { Link } from 'react-router-dom';
import classnames from "classnames";

import FotoModal from '../../assets/images/telkom/delete.png';
import Refresh from '../../assets/images/telkom/refresh.svg';


const I2 = () => {
  document.title = "Dashboard | Velzon - React Admin & Dashboard Template";



  // ---------- ON NEXT --------- //
  const [step, setStep] = useState(0);

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  // ~~~~~~~~~~ ON NEXT ~~~~~~~~~ //

  // --------- Tab --------- //
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  // ~~~~~~~~~~ TAB ~~~~~~~~~~ //

  // --------- Get All User -------- //
  const dummyPelamar = [
    {
      "id":"1",
      "nama":"Fernanda Putra",
      "email":"fernandes@gmail.com",
      "hp":"085712327898",
      "kode":"Bj0rk@",
      "alamat":"Depok Baru",
      "feedback":"Isi subjek interview sudah tersampaikan dengan baik",
      "tanggalLahir":"21 November 2000",
      "tanggalDaftar":"20 Juli 2022",
      "tanggalSubmit":"9 Agustus 2022",
    },
    {
      "id":"2",
      "nama":"Rahmad Adi Permana",
      "email":"adiadi2.com",
      "hp":"085712327898",
      "kode":"8eOi2A",
      "alamat":"Kebayoran Lama",
      "feedback":"Interview yang serius, tapi juga pembawaannya cheerful",
      "tanggalLahir":"21 November 2000",
      "tanggalDaftar":"20 Juli 2022",
      "tanggalSubmit":"18 November 2022",
    },
    {
      "id":"3",
      "nama":"Putra Fajar",
      "email":"matahaariii@gmail.com",
      "hp":"085932411898",
      "kode":"87i9E1x",
      "alamat":"Pondok Indah",
      "feedback":"Waktu menjawab error sehingga melebihi batas",
      "tanggalLahir":"21 November 2000",
      "tanggalDaftar":"20 Juli 2022",
      "tanggalSubmit":"1 Desember 2022",
    },
    {
      "id":"4",
      "nama":"Wahyu Penangsang",
      "email":"wahtups@gmail.com",
      "hp":"085715527898",
      "kode":"O0O0O0O",
      "alamat":"Bekasi Utara",
      "feedback":"Isi subjek interview sudah baik",
      "tanggalLahir":"21 November 2000",
      "tanggalDaftar":"20 Juli 2022",
      "tanggalSubmit":"20 Agustus 2022",
    },
  ];

  // ---------- Dynamic Form --------- //
  const [inputFields, setInputFields] = useState([]);
  const handleAdd = () => {
    const tambah = [...inputFields, { pertanyaan: '', type: '', waktu: 0 }]
    setInputFields(tambah)
  }
  const handleChange = (event, index) => {
    const ganti = [...inputFields]
    ganti[index].pertanyaan = event.target.value
    setInputFields(ganti)
  }
  const handleDelete = (index) => {
    const hapus = [...inputFields]
    hapus.splice(index, 1)
    setInputFields(hapus)
  }
  // ----------- Question ----------- //
  const handleChangeType = (event, index) => {
    const ganti = [...inputFields]
    ganti[index].type = event.target.value
    setInputFields(ganti)
  }
  const handleChangeWaktu = (event, index) => {
    const ganti = [...inputFields]
    ganti[index].waktu = event.target.value
    setInputFields(ganti)
  }

  // --------- MODAL DELETE ---------- //
  const [modalDelete, setModalDelete] = useState(false);
  function openModalDelete() {
    setModalDelete(!modalDelete);
  }

  // --------- MODAL TAMBAH DATA ---------- //
  const [modalTambahData, setModalTambahData] = useState(false);
  function openModalTambahData() {
    setModalTambahData(!modalTambahData);
  }

  const dummyEvent = [
    {
      "id":"1",
      "namaEvent":"Lowongan kerja Blockchain",
      "link":"uwuAble",
      "tanggalMulai":"17 September 2022",
      "tanggalBerakhir":"17 Oktober 2022",
      "status":"Selesai",
    },
    {
      "id":"2",
      "namaEvent":"Lowongan kerja UI UX",
      "link":"uwiwUwUx",
      "tanggalMulai":"30 September 2022",
      "tanggalBerakhir":"30 Oktober 2022",
      "status":"Berlangsung",
    },
    {
      "id":"3",
      "namaEvent":"Lowongan kerja Python 3.0",
      "link":"hEaDsNaKe",
      "tanggalMulai":"17 Januari 2023",
      "tanggalBerakhir":"1 Februari 2023",
      "status":"Berlangsung",
    },
  ]


  return (
    <React.Fragment>
      <div className="page-content">

        {step === 0 && (
          <>
            <div>
              <h1>Data Interview mtgyj</h1>
            </div>

            {/* Search & Button Hapus Tambah Data */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '35px', marginBottom: '30px' }}>

              <div className="search-box">
                <input type="text" className="form-control search" placeholder="Search..." />
                <i className="ri-search-line search-icon"></i>
              </div>

              <div style={{ display: 'flex', gap: '30px' }}>
                <Button style={{ padding: '0.5rem 3.0rem' }} onClick={() => openModalDelete()} color='danger'>Hapus Data</Button>
                <Button style={{ padding: '0.5rem 3.0rem' }} onClick={() => openModalTambahData()} color='success' >Tambah Data</Button>
              </div>

            </div>

            {/* ----------- Tabel ----------- */}
            <div className="table-responsive">
              <Table className="align-middle table-nowrap mb-0">
                <thead style={{ backgroundColor: '#FED7D7', color: '#E53E3E' }}>
                  <tr>
                    <th scope="col" style={{ width: "50px" }}>
                      <div className="form-check">
                        {/* <input className="form-check-input" type="checkbox" id="checkAll" value="option" /> */}
                      </div>
                    </th>
                    <th style={{width: 'max-content'}} scope="col">No</th>
                    <th scope="col">Nama</th>
                    <th scope="col">Tanggal Interview</th>
                    <th style={{textAlign: 'center'}} scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dummyEvent.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
                      </div>
                    </th>
                    <td>{1+index}</td>
                    <td>{item.namaEvent}</td>
                    <td>{item.tanggalMulai} - {item.tanggalBerakhir}</td>
                    {item.status === "Selesai" && (
                      <td style={{textAlign: 'center'}} className="status"><span className="badge badge-soft-success text-uppercase">Selesai</span></td>
                    )}
                    {item.status === "Berlangsung" && (
                      <td style={{textAlign: 'center'}} className="status"><span className="badge badge-soft-warning text-uppercase">Berlangsung</span></td>
                    )}
                    <td><Button className="badge bg-info" style={{ fontSize: '14px', borderColor: 'white' }}>Lihat</Button></td>
                  </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}

        {step === 1 && (
          <>
            <p>Beranda / <span style={{ color: '#4299E1' }}>Detail Interview</span></p>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <h2>Interview DDB Telkom</h2>
                <p>18 November 2022 - 18 Desember 2022</p>
                <p>Link Interview: https://tlkm.co/uwuable</p>
              </div>
              <div>
                <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-success" onClick={() => openModalTambahData()} >Edit</Button>
              </div>
            </div>

            <hr />

            <Row>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <Nav style={{ width: '400px' }} tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      Detail Pertanyaan
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      Feedback
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  activeTab={customActiveTab}
                  className="text-muted"
                >
                  <TabPane tabId="1" id="pertanyaan">
                    <Row>
                      <Col xxl={2} xl={6} lg={4} md={4} sm={4} xs={4} style={{ marginBottom: '15px' }}>
                        <div>
                          <Label htmlFor="batasWaktu" className="form-label">Batas Waktu Pertanyaan</Label>
                          <Input type='text' className="form-control" id='batasWaktu' />
                        </div>
                      </Col>
                      <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Card style={{ border: '1px solid #e5e5e5' }}>
                            <CardBody>

                              {inputFields.map((data, index) => (
                                <>
                                  <Row>
                                    <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                                      <div>
                                        <Label className="form-label">Pertanyaan {1 + index}</Label>
                                        <Input value={data.pertanyaan} onChange={event => handleChange(event, index)} type='text' className="form-control" />
                                      </div>
                                    </Col>
                                    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                                      <Label className="form-label">Type</Label>
                                      <select value={data.type} onChange={event => handleChangeType(event, index)} className="form-select mb-3" aria-label="Default select example">
                                        <option value="" selected="selected" hidden="hidden">Choose here</option>
                                        <option value="text">Text</option>
                                        <option value="video">Video</option>
                                        <option value="interviewer">Interviewer</option>
                                      </select>
                                    </Col>
                                    {data.type === 'video' && (
                                      <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                                        <div>
                                          <Label className="form-label">Batas Waktu Menjawab (Menit)</Label>
                                          <Input value={data.waktu} onChange={event => handleChangeWaktu(event, index)} type='number' className="form-control" />
                                        </div>
                                      </Col>

                                      )}

                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: '30px' }}>
                                      <Button style={{ padding: '0.5rem 2.0rem' }} onClick={() => { handleDelete(index) }} color='danger'>Hapus Data</Button>
                                    </div>
                                  </Row>
                                </>
                              ))}

                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button style={{ borderRadius: '100px' }} onClick={() => handleAdd()} size="lg" color="primary">+</Button>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button style={{ padding: '0.5rem 5.0rem' }} onClick={() => {console.log('simpan pertanyaan :)')}} color='success'>Simpan</Button>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">

                    <div className="table-responsive">
                      <Table className="align-middle table-nowrap mb-0">
                        <thead style={{ backgroundColor: '#FED7D7', color: '#E53E3E' }}>
                          <tr>
                            <th style={{ width: '120px', textAlign: 'center' }} scope="col">No</th>
                            <th style={{ width: '240px' }} scope="col">Nama</th>
                            <th scope="col">Pesan</th>
                          </tr>
                        </thead>
                        <tbody>
                        {dummyPelamar.map((item, index) => (
                          <tr key={index}>
                            <td style={{ width: '120px', textAlign: 'center' }}>{1+index}</td>
                            <td>{item.nama}</td>
                            <td>{item.feedback}</td>
                          </tr>
                        ))}
                        </tbody>
                      </Table>
                    </div>

                  </TabPane>
                </TabContent>
              </Col>
            </Row>


          </>
        )}

      </div>

      {/* ------- MODAL DELETE ------- */}
      <Modal isOpen={modalDelete} toggle={() => { openModalDelete() }} centered >
        <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={FotoModal} alt="FotoModal" />
          <h5 style={{ textAlign: 'center', marginTop: '30px' }}>Yakin Hapus Data?</h5>
          <p style={{ padding: '0 70px', textAlign: 'center' }}>Apakah anda yakin untuk menghapus data ini?</p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'space-evenly' }}>
          <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-light" onClick={() => { openModalDelete() }}>Batal</Button>
          <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-success" >Yakin</Button>
        </ModalFooter>
      </Modal>

      {/* --------- MODAL TAMBAH DATA -------- */}
      <Modal isOpen={modalTambahData} toggle={() => { openModalTambahData() }} centered >

        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => { openModalTambahData() }}>
          Data Interview
        </ModalHeader>

        <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <Row style={{ alignItems: 'center' }}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '15px' }}>
              <div>
                <Label htmlFor="judul" className="form-label">Judul</Label>
                <Input type='text' className="form-control" id='judul' />
              </div>
            </Col>


            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: '15px' }}>
              <div>
                <Label htmlFor="mulai" className="form-label">Tanggal Mulai</Label>
                <Input type='date' className="form-control" id='mulai' />
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: '15px' }}>
              <div>
                <Label htmlFor="berakhir" className="form-label">Tanggal Berakhir</Label>
                <Input type='date' className="form-control" id='berakhir' />
              </div>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '15px' }}>
              <div>
                <Label htmlFor="role" className="form-label">Role</Label>
                <Input type='text' className="form-control" id='role' />
              </div>
            </Col>

            <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10} style={{ marginBottom: '24px' }}>
              <div>
                <Label htmlFor="link" className="form-label">Link</Label>
                <Input type='text' className="form-control" id='link' />
              </div>
            </Col>

            <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
              <Button style={{ backgroundColor: '#ffffff', borderColor: '#ffffff' }}>
                <img src={Refresh} alt="Refresh" />
              </Button>
            </Col>

          </Row>

        </ModalBody>

        <ModalFooter style={{ justifyContent: 'space-evenly' }}>
          <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-light" onClick={() => { openModalTambahData() }}>Batal</Button>
          <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-success" onClick={() => { onNext(); openModalTambahData() }}>Kirim</Button>
        </ModalFooter>

      </Modal>

    </React.Fragment>
  );
};

export default I2;
