import React, { useState, useRef ,useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Col, Container, Row , Button, Input, Modal, ModalBody, ModalHeader, ModalFooter, Table, Label , Card, CardBody} from "reactstrap";
import axios from 'axios';

const I5 = () => {
  document.title = "Dashboard | Velzon - React Admin & Dashboard Template";

  const [eventList, setEventList] = useState([]);
  const [ambilId, setAmbilId] = useState('0');
  const [nilai, setNilaiAkhlak] = useState([]);
  const [nilaiApp, setNilaiApp] = useState([]);
  const [sumNilai, setSumNilai] = useState(0);
  const [pelamar, setPelamar] = useState([]);
  const [pelamarRN, setPelamarRN] = useState([]);
  const [pelamarNR, setPelamarNR] = useState([]);
  const [pelamarRWD, setPelamarRWD] = useState([]);
  const [dataUser, setDataUser] = useState({});


  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("user"));
    const user = JSON.parse(sessionStorage.getItem("user"));
    console.log("isi user i1=>", user );
    if (user) {
      getAllEventInterview(user.api_key);
    }
   }, [])
 
   const getAllEventInterview = (api_key) => {
     const config = {
       method: 'get',
       url: process.env.REACT_APP_BASE_ENDPOINT_BACKEND +'/assesor/eventlist',
       headers :{
        'x-api-key': api_key
       }
     };
     
     axios(config)
     .then(function (response) {
       setEventList(JSON.parse(JSON.stringify(response.data)));
     })
     .catch(function (error) {
       console.log(error);
     });  
   }
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [rating3, setRating3] = useState(0);
  const [rating4, setRating4] = useState(0);
  const [rating5, setRating5] = useState(0);
  
  const getPesertaById = (id) => {

    const config = {
      method: 'get',
      url: process.env.REACT_APP_BASE_ENDPOINT_BACKEND +`/assesor/pesertaEvent/${id}/${ambilId}`,
      headers :{
        "x-api-key" : dataUser.token
      }
    };

    axios(config)
      .then(function (response) {
        const data = response.data[0]
        penilaianStatusAKHLAK(data)
        penilaianStatusApp(data)
        const ratings = [
          data.aspek_Nilai_Amanah,
          data.aspek_Nilai_Kompeten,
          data.aspek_Nilai_Harmonis,
          data.aspek_Nilai_Loyal,
          data.aspek_Nilai_Adaptif,
          data.aspek_Nilai_Kolaboratif,
          data.appearance_Nilai_Impact,
          data.appearance_Nilai_OralCom
        ];
    
        setRating1( ratings.filter((rating) => rating === 1).length)
        setRating2( ratings.filter((rating) => rating === 2).length)
        setRating3( ratings.filter((rating) => rating === 3).length)
        setRating4( ratings.filter((rating) => rating === 4).length)
        setRating5( ratings.filter((rating) => rating === 5).length)
        
        setSumNilai(ratings.reduce((a, b) => a + b, 0));
        
        setNilaiAkhlak([
          { 
            jenis:"Amanah",
            nilai: data.aspek_Nilai_Amanah,
          },
          {
            jenis:"Kompeten",
            nilai: data.aspek_Nilai_Kompeten,
          },
          {
            jenis:"Harmonis",
            nilai: data.aspek_Nilai_Harmonis,
          },
          {
            jenis: "Loyal", 
            nilai: data.aspek_Nilai_Loyal,
          },
          {
            jenis: "Adaptif",
            nilai: data.aspek_Nilai_Adaptif,
          },
          {
            jenis: "Kolaboratif" ,
            nilai: data.aspek_Nilai_Kolaboratif,
          },
        
        ])

        setNilaiApp([
          
          
          {
            jenis: "Impact",
            nilai: data.appearance_Nilai_Impact,
          },
          {
            jenis: "OralCom",
            nilai: data.appearance_Nilai_OralCom,
          }
        ])
      
      
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  const [hasilPenilaianAKHLAK, setHasilPenilaianAKHLAK] = useState("Belum Ada Hasil");
  
  const penilaianStatusAKHLAK = (data) => {

    const ratings = [
      data.aspek_Nilai_Amanah,
      data.aspek_Nilai_Kompeten,
      data.aspek_Nilai_Harmonis,
      data.aspek_Nilai_Loyal,
      data.aspek_Nilai_Adaptif,
      data.aspek_Nilai_Kolaboratif,
    ];

   
    if (ratings.filter((rating) => rating === 0).length === 6) {
      setHasilPenilaianAKHLAK(`Belum Ada Hasil`);
    } else {
      if (
       // ratings.filter((rating) => rating === 0).length > 0 ||
        ratings.filter((rating) => rating === 1).length > 0 ||
        ratings.filter((rating) => rating === 2).length >= 3
      ) {
        setHasilPenilaianAKHLAK(`Not Ready At This Time`);
        data.statusNilai = `Not Ready At This Time`
        data.no = 1
        setPelamarNR(oldData => [...oldData, data])
      } else if (
        ratings.filter((rating) => rating === 3).length >= 3 ||
        ratings.filter((rating) => rating === 2).length >0
      ) {
        setHasilPenilaianAKHLAK(`Ready With Development`);
        data.statusNilai = `Ready With Development`
        data.no = 2
        
        setPelamarRWD(oldData => [...oldData, data])
      } 
      else {
        setHasilPenilaianAKHLAK(`Ready Now`);
        data.statusNilai = `Ready Now`
        data.no = 3
        setPelamarRN(oldData => [...oldData, data])
      }
    }
  }; 


  const [hasilPenilaianApp, setHasilPenilaianApp ] = useState("Belum Ada Hasil");
  
  const penilaianStatusApp = (data) => {

    const ratings = [
      data.appearance_Nilai_Impact,
      data.appearance_Nilai_OralCom
    ];

   
    if (ratings.filter((rating) => rating === 0).length === 6) {
      setHasilPenilaianApp(`Belum Ada Hasil`);
    } else {
      if (
       // ratings.filter((rating) => rating === 0).length > 0 ||
        ratings.filter((rating) => rating === 1).length > 0 ||
        ratings.filter((rating) => rating === 2).length > 0
      ) {
        setHasilPenilaianApp(`Not Ready At This Time`);
        data.statusNilai = `Not Ready At This Time`
        data.no = 1
        setPelamarNR(oldData => [...oldData, data])
      } else if (
        ratings.filter((rating) => rating === 3).length <=2 &&
        ratings.filter((rating) => rating === 3).length >0
      ) {
        setHasilPenilaianApp(`Ready With Development`);
        data.statusNilai = `Ready With Development`
        data.no = 2
        
        setPelamarRWD(oldData => [...oldData, data])
      } 
      else  {
        setHasilPenilaianApp(`Ready Now`);
        data.statusNilai = `Ready Now`
        data.no = 3
        
        setPelamarRN(oldData => [...oldData, data])
      }
    }
  };

  

   const getAllEventPeserta = () => {

    const config = {
      method: 'get',
      url: process.env.REACT_APP_BASE_ENDPOINT_BACKEND +`/assesor/pesertaFinished/${ambilId}`,
    };

    axios(config)
      .then(function (response) {
        const data = response.data;
        
        data.forEach(element => {
          penilaianStatusAKHLAK(element);
          penilaianStatusApp(element)
          element.nilai = (element.aspek_Nilai_Amanah + element.aspek_Nilai_Kompeten + element.aspek_Nilai_Adaptif+
                          element.aspek_Nilai_Harmonis + element.aspek_Nilai_Kolaboratif + element.aspek_Nilai_Loyal +
                          element.appearance_Nilai_Impact+ element.appearance_Nilai_OralCom);
        });
        setPelamar(JSON.parse(JSON.stringify(data)));
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  // --------- MODAL ---------- //
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
      setmodal_list(!modal_list);
  }

  // --------- MODAL DOWNLOAD ---------- //
  const [modalDetail, setModalDetail] = useState(false);
  function openModalDetail() {
    setModalDetail(!modalDetail);
  }


  // --------- Get All User -------- //
 
  const sort = pelamar.sort((a,b) => {return b.nilai - a.nilai})
  const sorting = sort.sort((a,b) => {return b.no - a.no})
  const [data, setData] = useState(sorting);
  const [order, setOrder] = useState('ASC');
  const sortings = (col) => {

    if(order ==='ASC'){
      const sorted =[...data].sort((a, b)=> 
      a[col].toLowerCase()> b[col].toLowerCase()? 1:-1
      )
    setData(sorted);
    setOrder('DSC')
    }
    if(order ==='DSC'){
      const sorted =[...data].sort((a, b)=> 
      a[col].toLowerCase()< b[col].toLowerCase()? 1:-1
    )
    setData(sorted);
    setOrder('ASC')
  }
  };
  


  // ------------- Get By ID ------------ //
  const [getId, setGetId] = useState()
  const handleChange = (nomor) =>{
    setGetId(nomor)
  }
 

  // ------------ Print PDF ------------ //
  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: `hasil-jawaban-perserta-${getId}`,
  })


 
  return (
    <React.Fragment>
      <div className="page-content">
    
        <>
        <div>
          <h1>Lihat Hasil Jawaban Interview Pelamar</h1>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '35px', marginBottom: '30px' }}>

                <p style={{ margin: '0', fontWeight: '700', fontSize: '16px', color: '#4A5568' }}>Pilih Data Interview</p>

                <select style={{ width: '320px', marginBottom: '10px' }} value={ambilId} onChange={(event) => { setAmbilId(event.target.value) }} onClick={() => { getAllEventPeserta() }} className="form-select" aria-label="Default select example">
                  <option value="0" selected="selected" hidden="hidden">Choose here</option>
                  {eventList.map((item, index) => (
                    <>
                      <option value={item.id}>{item.judul_Interview}</option>
                    </>
                  ))}
                </select>

                <div style={{ width: '360px', margin: '10px 0px' }} className="search-box">
                  <input type="text" className="form-control search" placeholder="Search..." />
                  <i style={{ height: '40px' }} className="ri-search-line search-icon"></i>
                </div>

              </div>
            </div>

        <div className="table-responsive">
          <Table className="align-middle table-nowrap mb-0 table-fixed">
              <thead style={{backgroundColor: '#FED7D7', color: '#E53E3E'}}>
                  <tr>
                      
                      <th scope="col">Ranking</th>
                      <th scope="col">Nama</th>
                      <th scope="col" >Total Nilai</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                  </tr>
              </thead>
              <tbody>
              {sorting.map((item, index) => (
                <tr key={index}>
                 
                  <td>{1+index}</td>
                  <td>{item.nama}</td>
                  <td>{item.nilai}</td>
                  {item.statusNilai ==="Ready Now" && (
                  <>
                  <td  className="status"><span style={{fontSize: '10px', fontWeight: '600', padding: '5px 10px', backgroundColor: '#f0fff4', color: '#38a169', borderRadius: '8px', textAlign: 'center'}} >Ready Now</span></td>
                  </>
                  )  }
                  {item.statusNilai ==="Not Ready At This Time" && (
                  <td  className="status"><span style={{fontSize: '10px', fontWeight: '600', padding: '5px 10px', backgroundColor: '#fff5f5', color: '#e53e3e', borderRadius: '8px', textAlign: 'center'}}>Not Ready At This Time</span></td>
                  )}
                  {item.statusNilai ==="Ready With Development" && (
                  <td  className="status"><span style={{fontSize: '10px', fontWeight: '600', padding: '5px 10px', backgroundColor: '#fefcbf', color: '#d69e2e', borderRadius: '8px', textAlign: 'center'}}>Ready With Development</span></td>
                  )}
                  <td><Button onClick={() => {openModalDetail(); getPesertaById(item.id)}} className="btn btn-info" style={{fontSize: '14px'}}>Lihat</Button></td>
                </tr>
              ))}
              </tbody>
          </Table>
        </div>
        </>
     

      </div>
    

      {/* ---------- MODAL DETAIL PELAMAR ----------- */}
      <Modal isOpen={modalDetail} toggle={() => {openModalDetail()}} centered >

        <ModalHeader className="modal-title" id="myModalLabel" toggle={() => {openModalDetail()}}>
          <p style={{margin: '0px', color: '#2A4365', fontSize: '20px', fontWeight: '700'}}>Detail Nilai</p>
        </ModalHeader>

        <ModalBody>
        <Table className="align-middle table-nowrap mb-0 center" >
          <thead style={{backgroundColor: '#E6FFFA'}}>
            <tr>
                   
              <th scope="col">No</th>
              <th scope="col">Aspek Penilaian Akhlak</th>
              <th scope="col">Nilai</th>
                    
            </tr>
          </thead>
          <tbody>
             
            {nilai.map((item, index) => (
              <tr key={index}>
                  
                <td>{1+index}</td>
                <td>{item.jenis}</td>
                <td>{item.nilai}</td>

              </tr>
                
            ))}
            {/* <tr className="text-white"  style={{backgroundColor: '#319795'}}>
              <td colSpan={2}>Total Nilai Keseluruhan </td>
              <td>{sumNilai}</td>
            </tr> */}
            
          </tbody>
        </Table>
        {hasilPenilaianAKHLAK === 'Ready Now'&&(
          <p> Aspek Penilaian AKHLAK Peserta Adalah: <span className=" text-success"> {hasilPenilaianAKHLAK} </span> </p>
            // rating4 >3?(
            // <p> Pelamar dinyatakan <span className=" text-success"> {hasilPenilaianAKHLAK} </span> dalam seleksi dengan nilai 4 lebih dari 3 </p>
            // ):(
            //   <p> Pelamar dinyatakan <span className=" text-success"> {hasilPenilaianAKHLAK} </span> dalam seleksi dengan nilai 5 lebih dari {rating5-1} </p>

            // )
          )}
          {hasilPenilaianAKHLAK === 'Ready With Development'&&(
            <p> Aspek Penilaian AKHLAK Peserta Adalah: <span className=" text-warning"> {hasilPenilaianAKHLAK} </span> </p>
            // rating3 >2?(
            // <p> Pelamar dinyatakan <span className=" text-warning"> {hasilPenilaianAKHLAK} </span> dalam seleksi dengan nilai 3 lebih dari 2 </p>
            // ):(
            //   <p> Pelamar dinyatakan <span className=" text-warning"> {hasilPenilaianAKHLAK} </span> dalam seleksi dengan nilai 2 lebih dari 0 </p>

            // )
          )}
          {hasilPenilaianAKHLAK === 'Not Ready At This Time'&&(
            <p> Aspek Penilaian AKHLAK Peserta Adalah: <span className=" text-danger"> {hasilPenilaianAKHLAK} </span> </p>
            // rating2 > 2?(
            // <p> Pelamar dinyatakan <span className=" text-danger"> {hasilPenilaianAKHLAK} </span> dalam seleksi dengan nilai 2 lebih dari 2 </p>
            // ):(
            //   <p> Pelamar dinyatakan <span className=" text-danger"> {hasilPenilaianAKHLAK} </span> dalam seleksi dengan nilai 1 lebih dari 0 </p>

            // )
          )}

        <Table className="align-middle table-nowrap mb-0 center" >
          <thead style={{backgroundColor: '#E6FFFA'}}>
            <tr>
                   
              <th scope="col">No</th>
              <th scope="col">Aspek Penilaian Appearance</th>
              <th scope="col">Nilai</th>
                    
            </tr>
          </thead>
          <tbody>
             
            {nilaiApp.map((item, index) => (
              <tr key={index}>
                  
                <td>{1+index}</td>
                <td>{item.jenis}</td>
                <td>{item.nilai}</td>

              </tr>
                
            ))}
            {/* <tr className="text-white"  style={{backgroundColor: '#319795'}}>
              <td colSpan={2}>Total Nilai Keseluruhan </td>
              <td>{sumNilai}</td>
            </tr> */}
            
          </tbody>
        </Table>
          {/* <div style={{marginTop: '10px'}}> <b >Keterangan</b> </div>
         
          <div style={{margin: '10px', backgroundColor: '#F7FAFC'}} className="text-muted">

            
          <Row>
            <Col> Jumlah Rating 1: {rating1}</Col>
            <Col> Jumlah Rating 2: {rating2}</Col>
          </Row>
          <Row>
            <Col> Jumlah Rating 3: {rating3}</Col>
            <Col> Jumlah Rating 4: {rating4}</Col>
          </Row>
          <Row>
            <Col> Jumlah Rating 5: {rating5}</Col>
          </Row>


          </div> */}
          {hasilPenilaianApp === 'Ready Now'&&(
            <p> Aspek Penilaian Appearance Peserta Adalah: <span className=" text-success"> {hasilPenilaianApp} </span> </p>
            // rating4 >3?(
            // <p> Pelamar dinyatakan <span className=" text-success"> {hasilPenilaianApp} </span> dalam seleksi dengan nilai 4 lebih dari 3 </p>
            // ):(
            //   <p> Pelamar dinyatakan <span className=" text-success"> {hasilPenilaianApp} </span> dalam seleksi dengan nilai 5 lebih dari {rating5-1} </p>

            // )
          )}
          {hasilPenilaianApp === 'Ready With Development'&&(
             <p> Aspek Penilaian Appearance Peserta Adalah: <span className=" text-warning"> {hasilPenilaianApp} </span> </p>
            // rating3 >2?(
            // <p> Pelamar dinyatakan <span className=" text-warning"> {hasilPenilaianApp} </span> dalam seleksi dengan nilai 3 lebih dari 2 </p>
            // ):(
            //   <p> Pelamar dinyatakan <span className=" text-warning"> {hasilPenilaianApp} </span> dalam seleksi dengan nilai 2 lebih dari 0 </p>

            // )
          )}
          {hasilPenilaianApp === 'Not Ready At This Time'&&(
             <p> Aspek Penilaian Appearance Peserta Adalah: <span className=" text-danger"> {hasilPenilaianApp} </span> </p>
            // rating2 > 2?(
            // <p> Pelamar dinyatakan <span className=" text-danger"> {hasilPenilaianApp} </span> dalam seleksi dengan nilai 2 lebih dari 2 </p>
            // ):(
            //   <p> Pelamar dinyatakan <span className=" text-danger"> {hasilPenilaianApp} </span> dalam seleksi dengan nilai 1 lebih dari 0 </p>

            // )
          )}

        </ModalBody>

      </Modal>

    </React.Fragment>
  );
};

export default I5;
