import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Input, Label, Row ,Button} from 'reactstrap';
import AuthSlider from '../authCarousel';

import logoTelkom from "../../../assets/images/telkom/logoACI.png";

const CoverSignUpterview = () => {
    document.title="Cover SignIn | Velzon - React Admin & Dashboard Template";

    return (
        <React.Fragment>

            {/* dihapus auth-bg-cover */}
            <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>

                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">

                                        {/* Ini Welcome! Lets Get Started */}
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div style={{paddingRight: '3rem', paddingLeft: '3rem'}}>
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    <img src={logoTelkom} alt="" width="30%" />
                                                    {/* <h5 className="text-primary">Welcome Back !</h5>
                                                    <p className="text-muted">Sign in to continue to Velzon.</p> */}
                                                </div>

                                                <Col lg={10} lg={{ size: 10, offset: 1 }} style={{marginTop: '20px', padding: '10px 25px 30px' ,boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)', borderRadius: '8px'}}>
                                                    <p style={{fontWeight: '700', fontSize: '30px', color: '#F56565', textAlign: 'center', margin: '0'}}>REGISTER</p>
                                                    <form action="/login-interview">

                                                        <div className="mb-3">
                                                            <Label htmlFor="nama" className="form-label">Nama</Label>
                                                            <Input type="text" className="form-control" id="nama" placeholder="Tulis Sesuatu" required/>
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label htmlFor="email" className="form-label">Email</Label>
                                                            <Input type="text" className="form-control" id="email" placeholder="Enter Email" required/>
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="password-input">Password</Label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <Input type="password" className="form-control pe-5" placeholder="Enter password" id="password-input" required/>
                                                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                            </div>
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="password-conf">Confirm Password</Label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <Input type="password" className="form-control pe-5" placeholder="Enter password" id="password-conf" required/>
                                                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                            </div>
                                                        </div>
                                                        

                                                        <div className="mt-4">
                                                            <Button color="success" className="w-100" type="submit">Masuk</Button>
                                                        </div>

                                                    </form>
                                                </Col>

                                                {/* <div className="mt-5 text-center">
                                                    <p className="mb-0">Don't have an account ? <a href="/auth-signup-cover" className="fw-semibold text-primary text-decoration-underline"> Signup</a> </p>
                                                </div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </React.Fragment>
    );
};

export default CoverSignUpterview;