import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, Col, Container, Input, Label, Row, Button, Modal, ModalBody } from 'reactstrap';
import AuthSlider from '../authCarousel';
import axios from 'axios';
// import ReCAPTCHA from 'react-google-recaptcha'

import logoTelkom from "../../../assets/images/telkom/logoACI.png";
import Welcome from "../../../assets/images/telkom/welcomeNew.png";

const CoverSignInterview = () => {
    document.title = "Login Assessor";

    if (localStorage.getItem('user') != null) {
        window.localStorage.clear()
    }
    if (sessionStorage.getItem('user') != null) {
        window.location.href = '/i1';
    }

    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [errorSubmit, setErrorSubmit] = useState(true);
    const [errorChange, setErrorChange] = useState(true);
    const [showPass, setShowPass] = useState(false);



    // 15 MEI 2023 //
    const [modalChangePassword, setModalChangePassword] = useState(false);
    const [getApiKey, setGetApiKey] = useState("")
    const [passwordCurrent, setPasswordCurrent] = useState('');
    const [passwordNew, setPasswordNew] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    // const [modalReCAPTCHA, setModalReCAPTCHA] = useState(false);

    // ReCAPTCHA
    // const captchaRef = useRef(null)

    const changeEmail = (event) => {
        const value = event.target.value
        setEmail(value)
        if (!value) {
            setErrorEmail('Maaf, email tidak boleh kosong!')
        } else {
            setErrorEmail('')
        }
    }

    const changePassword = (event) => {
        const value = event.target.value
        setPassword(value)
        if (!value) {
            setErrorPassword('Maaf, password tidak boleh kosong!')
        } else {
            setErrorPassword('')
        }
    }

    const changePasswordCurrent = (event) => {
        const value = event.target.value
        setPasswordCurrent(value)
        if (!value) {
            setErrorPassword('Maaf, password tidak boleh kosong!')
        } else {
            setErrorPassword('')
        }
    }

    const changePasswordNew = (event) => {
        const value = event.target.value
        setPasswordNew(value)
        if (!value) {
            setErrorPassword('Maaf, password tidak boleh kosong!')
        } else {
            setErrorPassword('')
        }
    }

    const changePasswordConfirm = (event) => {
        const value = event.target.value
        setPasswordConfirm(value)
        if (!value) {
            setErrorPassword('Maaf, password tidak boleh kosong!')
        } else {
            setErrorPassword('')
        }
    }

    const shower = () => {
        setShowPass(!showPass);
    }

    const [modalLimitLogin, setModalLimitLogin] = useState(false)

    const loginButton = () => {

        // if (captchaRef.current.getValue() !== "") {
        const data = JSON.stringify({
            email: email,
            password: password,
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/assesor/login`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        };

        axios(config).then((response) => {

            if (response.results.otp === null) {
                setModalChangePassword(true)
                setGetApiKey(response.results.token)
            } else {
                // localStorage.setItem('user', JSON.stringify(response))
                sessionStorage.setItem('user', JSON.stringify(response))
                window.location.href = '/i1'
            }

        }).catch((error) => {

            if (error.response.data.error == "Request failed with status code 429") {
                setModalLimitLogin(true)
            } else {
                setErrorSubmit(false);
                setTimeout(() => {
                    setErrorSubmit(true)
                }, 5000);
            }


        })
        // } else {
        //     setModalReCAPTCHA(true)
        // }


    }

    const firstLogin = () => {
        const data = JSON.stringify({
            email: email,
            currentPass: passwordCurrent,
            newPass: passwordNew,
            confPass: passwordConfirm,
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/assesor/first-login`,
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': getApiKey
            },
            data: data,
        };

        axios(config).then((response) => {
            // localStorage.setItem('user', JSON.stringify(response))
            sessionStorage.setItem('user', JSON.stringify(response))
            window.location.href = '/i1'
            setModalChangePassword(false);
        }).catch((error) => {
            setErrorChange(false);
            setTimeout(() => {
                setErrorChange(true)
            }, 5000);
        })
    }



    return (
        <React.Fragment>
            <Row style={{ margin: '0px', height: '100vh', alignItems: 'center' }}>
                {/* Ini Welcome! Lets Get Started */}
                <Col md={12} lg={6} xl={6} style={{ height: '100%', padding: '0px' }}>
                    <img src={Welcome} style={{ height: '-webkit-fill-available' }} alt="Welcome" />
                </Col>

                <Col md={12} lg={6} xl={6} style={{ padding: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <div style={{ display: 'flex', justifyContent: 'center', marginRight: '50px' }}>
                        <img src={logoTelkom} alt="logoTelkom" />
                    </div>

                    <div style={{ marginTop: '50px', padding: '25px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.08)', borderRadius: '8px', width: '65%', marginRight: '50px' }}>
                        <p style={{ fontWeight: '700', fontSize: '30px', color: '#F56565', textAlign: 'center' }}>Masuk</p>
                        <Alert hidden={errorSubmit} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s' }}>
                            The Email and/or password you specified are not correct.
                        </Alert>

                        <div className="mb-3">
                            <Label htmlFor="email" className="form-label">Email</Label>
                            <Input type="text" value={email} onChange={changeEmail} className="form-control" id="email" placeholder="Enter Email" required />
                            {errorEmail && (
                                <p style={{ margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute' }}>{errorEmail}</p>
                            )}
                        </div>

                        <div className="mb-3">
                            <Label className="form-label" htmlFor="password-input">Password</Label>
                            <div className="position-relative auth-pass-inputgroup">
                                <Input type={showPass ? "text" : "password"} value={password} onChange={changePassword} className="form-control pe-5" placeholder="Enter password" id="password-input" required />
                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={shower} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                            </div>
                            {errorPassword && (
                                <p style={{ margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute' }}>{errorPassword}</p>
                            )}
                        </div>

                        {/* <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} /> */}

                        <div className="mt-4">
                            <Button color="success" className="w-100" onClick={loginButton}>Masuk</Button>
                        </div>
                    </div>
                </Col>
            </Row>

            {/* ------- MODAL CAPTCHA ------- */}
            {/* <Modal isOpen={modalReCAPTCHA} toggle={() => { }} centered>
                <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h5 style={{ textAlign: "center", padding: "0 70px", margin: '50px 0px' }}>
                            reCAPTCHA dulu kawan
                        </h5>
                        <Button color="success" onClick={() => { setModalReCAPTCHA(false) }}>Oke Deh</Button>
                    </div>
                </ModalBody>
            </Modal> */}

            <Modal isOpen={modalChangePassword} toggle={() => { }} centered>

                <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={logoTelkom} alt="logoTelkom" />
                        </div>
                        <div style={{ padding: "0px 5%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h2 style={{ textAlign: "center", margin: '20px 0px', fontWeight: '700' }}>
                                Selamat Datang di Web Interview Telkom ACI Assessor
                            </h2>
                            <p style={{ fontSize: '14px', textAlign: 'center' }}>Silahkan untuk menganti password setelah di berikan oleh Admin, pastikan anda selalu mengingatnya yaa...</p>
                            <Alert hidden={errorChange} color="warning" style={{ textAlign: 'center', backgroundColor: '#FFF3B5', color: '#D88E00', borderColor: '#FFF3B5', margin: '10px 0px', transition: '0.5s', width: '100%' }}>
                                Something wrong
                            </Alert>
                            <div className="mb-3" style={{ width: '100%', fontSize: '14px' }}>
                                <Label className="form-label" htmlFor="password-input">Current Password</Label>
                                <div className="position-relative auth-pass-inputgroup">
                                    <Input type={showPass ? "text" : "password"} value={passwordCurrent} onChange={changePasswordCurrent} className="form-control pe-5" placeholder="Enter password" id="password-input" required />
                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={shower} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                </div>
                                {errorPassword && (
                                    <p style={{ margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute' }}>{errorPassword}</p>
                                )}
                            </div>
                            <div className="mb-3" style={{ width: '100%', fontSize: '14px' }}>
                                <Label className="form-label" htmlFor="password-input">New Password</Label>
                                <div className="position-relative auth-pass-inputgroup">
                                    <Input type={showPass ? "text" : "password"} value={passwordNew} onChange={changePasswordNew} className="form-control pe-5" placeholder="Enter password" id="password-input" required />
                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={shower} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                    <p style={{ margin: '0', color: '#ED8936', fontSize: '10px' }}>*Kata sandi minimal 8 karakter harus terdiri atas huruf kapital, angka, dan simbol.</p>
                                </div>
                                {errorPassword && (
                                    <p style={{ margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute' }}>{errorPassword}</p>
                                )}
                            </div>
                            <div className="mb-3" style={{ width: '100%', fontSize: '14px' }}>
                                <Label className="form-label" htmlFor="password-input">Confirm Password</Label>
                                <div className="position-relative auth-pass-inputgroup">
                                    <Input type={showPass ? "text" : "password"} value={passwordConfirm} onChange={changePasswordConfirm} className="form-control pe-5" placeholder="Enter password" id="password-input" required />
                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={shower} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                </div>
                                {errorPassword && (
                                    <p style={{ margin: '0', color: '#F56565', fontSize: '10px', position: 'absolute' }}>{errorPassword}</p>
                                )}
                            </div>
                        </div>

                        <Button color="success" onClick={() => { firstLogin() }}>Change Password</Button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalLimitLogin} toggle={() => { }} centered>
                <ModalBody style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h5 style={{ textAlign: "center", padding: "0 50px", margin: '50px 0px' }}>
                            Terlalu banyak melakukan percobaan,<br />Mohon tunggu beberapa menit
                        </h5>
                        <Button color="success" onClick={() => { setModalLimitLogin(false) }}>Oke Deh</Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default CoverSignInterview;