import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Input, Modal, ModalBody, ModalHeader, ModalFooter, Select, Table, Label, Alert} from "reactstrap";
import { Link, useHistory } from 'react-router-dom';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import FotoModal from "../../assets/images/telkom/modal.png";

import jumlahPerserta from "../../assets/images/telkom/jumlahPerserta.png";
import jumlahFeedback from "../../assets/images/telkom/jumlahFeedback.png";
import telahSubmit from "../../assets/images/telkom/telahSubmit.png";
import Refresh from "../../assets/images/telkom/refresh.svg";

import Timeout from '../../assets/images/telkom/timeout.svg';

import axios from "axios";

import crypto from "../Setting/crypto.js";

const I1 = () => {
  document.title = "Dashboard Assessor";

  // --------- LOCAL STORAGE ---------- // 
  const [dataUser, setDataUser] = useState({});

  // const [nama, setNama] = useState(JSON.parse(localStorage.getItem('user')).results.nama);
  const [nama, setNama] = useState(JSON.parse(sessionStorage.getItem('user')).results.nama);
  const [eventList, setEventList] = useState([]);
  const [ambilIdEvent, setAmbilIdEvent] = useState(sessionStorage.getItem("pilihEvent"));

  // -------- Get Number Count ------- //
  const [assessed, setAssessed] = useState(0);
  const [notAssessed, setNotAssessed] = useState(0);

  // ---------- GET ALL EVENT PELAMAR ------------- //
  const [pelamar, setPelamar] = useState([]);
  const [pelamarById, setPelamarById] = useState();

  const [event, setEvent] = useState([]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [eventOnDate, setEventOnDate] = useState([]);
  const [errorAdd, setErrorAdd] = useState(true);
  const [message, setMessage] = useState("");

  const [namato, setNamato] = useState("");
  const [emailto, setEmailto] = useState("");
  const [noHPto, setNoHPto] = useState("");
  const [alamatto, setAlamatto] = useState("");
  const [tanggalLahirto, setTanggalLahirto] = useState("");

  const [modalDetailPelamar, setModalDetailPelamar] = useState(false);
  const openModalDetailPelamar = () => {
    setModalDetailPelamar(!modalDetailPelamar);
  };
  const [modalAgenda, setModalAgenda] = useState(false);
  const openModalAgenda = () => {
    setModalAgenda(!modalAgenda);
  };

  const [select, setSelect] = useState();
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [errorTanggalMulai, setErrorTanggalMulai] = useState("");
  const [tanggalBerakhir, setTanggalBerakhir] = useState("");
  const [errorTanggalBerakhir, setErrorTanggalBerakhir] = useState("");
  const [errorTanggal, setErrorTanggal] = useState(true);

  // -------- Buat jalanin Get All ------- //
  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("user"));
    const user = JSON.parse(sessionStorage.getItem("user"));

    if (user) {
      console.log(user.results)
      setDataUser(user.results);

      getAllEventInterview(user.results.token);
      defaultGetAllEventPeserta(user.results.token);


    } else {
      window.location.href = "/login-assesor";
    }

  }, [] ); // eslint-disable-line

  // -------- Get All Event Interview -------- //
  const getAllEventInterview = (api_key) => {
  
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/assesor/eventWithAssessor/${nama}`,
      headers: {
        "x-api-key" : api_key
      },
    };
    
    axios(config)
    .then(function (response) {
        const data = response.data;
        console.log("data =>", response);
        console.log("type data =>", typeof(data));
        const bln = [ "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];

        data.forEach((e) => {
          let a = new Date(e.tanggal_Mulai);
          let b = new Date(e.tanggal_Berakhir);
          for (let i = 0; i < bln.length; i++) {
            if (i === a.getMonth()) {
              e.bulanMulaiName = bln[i];
            }
            if (i === b.getMonth()) {
              e.bulanAkhirName = bln[i];
            }
          }
          e.bulan = a.getMonth() + 1;
          e.bulanAkhir = b.getMonth() + 1;
          e.tahun = a.getFullYear();
          e.tahunAkhir = b.getFullYear();
          e.tanggal = a.getDate();
          e.tanggalAkhir = b.getDate();
        });

        setEventList(JSON.parse(JSON.stringify(data)));
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  // ------- Get Number Count ------- //
  const countAssessed = (api_key,id_event) => {

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/assesor/finished/${nama}/${id_event}`,
      headers :{
        "x-api-key" : api_key
      }
    };

    axios(config)
      .then(function (response) {
        setAssessed(JSON.parse(JSON.stringify(response.data.count)));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const countNotAssessed = (api_key,id_event) => {

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/assesor/notFinish/${nama}/${id_event}`,
      headers :{
        "x-api-key" : api_key
      }
    };

    axios(config)
      .then(function (response) {
        setNotAssessed(JSON.parse(JSON.stringify(response.data.count)));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const defaultGetAllEventPeserta = (api_key) => {

    countNotAssessed(api_key, ambilIdEvent)
    countAssessed(api_key, ambilIdEvent)

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/assesor/pesertaSelesaiWithAssessor/${nama}/${ambilIdEvent}`,
      headers :{
        "x-api-key" : api_key
      }
    };

    axios(config)
      .then(function (response) {
        setPelamar(JSON.parse(JSON.stringify(response.data)));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeGetAllEventPeserta = (api_key, id_event) => {

    countNotAssessed(api_key,id_event)
    countAssessed(api_key, id_event)

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/assesor/pesertaSelesaiWithAssessor/${nama}/${id_event}`,
      headers :{
        "x-api-key" : api_key
      }
    };

    axios(config)
      .then(function (response) {
        setPelamar(JSON.parse(JSON.stringify(response.data)));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // --------- Lihat Selengkapnya ---------- //
  const history = useHistory();
  const lihatSelengkapPelamar = () => {
    history.push("/onProcess");
  };

  const getDateNow = () => {
    const date = new Date();
    const bulan = date.getMonth();
    const tahun = date.getFullYear();

    setMonth(bulan + 1);
    setYear(tahun);
  };

  function getPesertaByIdDetail(id) {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/assesor/pesertaEvent/${id}/${ambilIdEvent}`,
      headers :{
        "x-api-key" : dataUser.token
      }
    };

    axios(config)
      .then(function (response) {
        const a = response.data;
        const b = a[0];

        setNamato(crypto.aesDecryptIdentitas(b.nama));
        setEmailto(crypto.aesDecryptIdentitas(b.email));
        setNoHPto(crypto.aesDecryptIdentitas(b.no_HP));
        setAlamatto(crypto.aesDecryptIdentitas(b.alamat));
        const date = new Date(b.tanggal_Lahir);
        const tgll = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
        const tgl = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        if(b.tanggal_Lahir !== null ){
          setTanggalLahirto(tgl);
        }else{
          setTanggalLahirto("---");
        }
          
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const changeTanggalMulai = (event) => {
    const value = event.target.value;
    setTanggalMulai(value);
    if (!value) {
      setErrorTanggalMulai("Maaf, Tanggal Mulai tidak boleh kosong!");
    } else {
      setErrorTanggalMulai("");
    }
  };

  const changeTanggalBerakhir = (event) => {
    const value = event.target.value;
    setTanggalBerakhir(value);
    if (!value) {
      setErrorTanggalBerakhir("Maaf, Tanggal Berakhir tidak boleh kosong!");
    } else {
      setErrorTanggalBerakhir("");
    }
  };

  const selectEvent = () => {
    setErrorAdd(true);
    setErrorTanggal(true);

    const data = JSON.stringify({
      from: tanggalMulai,
      to: tanggalBerakhir,
    });

    const date1 = new Date(tanggalMulai);
    const date2 = new Date(tanggalBerakhir);

    if (date2 < date1) {
      setErrorTanggal(false);
      setEventOnDate([])
      setTimeout(() => {
        setErrorTanggal(true);
      }, 5000);
    } else {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/assesor/eventOnDate/${nama}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          const data = response.data;
          const bln = [
            "Januari",
            "Februari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Agustus",
            "September",
            "Oktober",
            "November",
            "Desember",
          ];

          data.forEach((e) => {
            let a = new Date(e.tanggal_Mulai);
            let b = new Date(e.tanggal_Berakhir);
            for (let i = 0; i < bln.length; i++) {
              if (i === a.getMonth()) {
                e.bulanMulaiName = bln[i];
              }
              if (i === b.getMonth()) {
                e.bulanAkhirName = bln[i];
              }
            }
            e.bulan = a.getMonth() + 1;
            e.tahun = a.getFullYear();
            e.tahunAkhir = b.getFullYear();
            e.tanggal = a.getDate();
            e.tanggalAkhir = b.getDate();
          });
          if(data.length === 0 ){
            setMessage(" ------------------------- Tidak Ada Agenda -------------------------- ")
          }
          setEventOnDate(data);
        })
        .catch(function (error) {
          setErrorAdd(false);
          console.log(error);
          setTimeout(() => {
            setErrorAdd(true)
        }, 5000)
        });
    }
  };

  const selectDataInterview = (id_event) =>{

    setAmbilIdEvent(id_event);
    sessionStorage.setItem("pilihEvent", id_event);

    changeGetAllEventPeserta(dataUser.token, id_event);
  }

  // --------- MODAL JWT ---------- //
  const [modalJWT, setModalJWT] = useState(false);
  // ----------- CHECK JWT --------- //
  const checkJWT = () => {
  
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/assesor/checkJwt`,
      headers: {
        "x-api-key": dataUser.token,
      },
    };
  
    axios(config)
      .then(function (response) {
        if (response.status === 403) {
          setModalJWT(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
 

  return (
    <React.Fragment>
      <div className="page-content">
        <div>
          <h1 style={{color: '#2A4365'}}>Selamat Datang, Assessor</h1>
        </div>

        {/* -------- DROP DOWN -------- */}
        <div style={{ width: "320px", marginTop: "20px" }}>
          <p style={{ marginBottom: "5px", fontSize: "14px", fontWeight: "700", color: "#4A5568"}}>
            Pilih Data Interview
          </p>
          <select
            className="form-select mb-3"
            aria-label="Default select example"
            value={ambilIdEvent}
            onChange={(event) => {
              // setAmbilIdEvent(event.target.value);
              selectDataInterview(event.target.value);
            }}
            // onClick={() => {
            //   getAllEventPeserta(dataUser.api_key);
            // }}
          >
            <option value="" selected="selected" hidden="hidden">
              Choose here
            </option>
            {eventList.map((item) => (
              <>
                <option value={item.id_Event}>{item.judul_Interview}</option>
              </>
            ))}
          </select>
        </div>

        {/* -------- MENU LIST -------- */}
        <Row style={{ marginTop: "20px", marginBottom: "30px", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "row", gap: "65px"}}>
          <Col xs="3" className="kartu" style={{ borderRadius: "10px", padding: "20px 30px", width: "320px", boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033"}}>
            <p style={{ fontSize: "18px", fontWeight: "400", color: "#718096" }}>
              Pelamar Telah Dinilai
            </p>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <h1 style={{ margin: "0", color: "#2A4365", fontSize: "40px" }}>
                {assessed}
              </h1>
              <div>
                <img src={telahSubmit} alt="telahSubmit"/>
              </div>
            </div>
            <hr /> 
            <span style={{ fontSize: "16px", fontWeight: "700", color: "#319795", cursor: "pointer"}} onClick={lihatSelengkapPelamar}>
              Lihat selengkapnya ...
            </span>
          </Col>
          <Col
            xs="3"
            className="kartu"
            style={{
              borderRadius: "10px",
              padding: "20px 30px",
              width: "320px",
              boxShadow: "0px 2px 5px #0000001a, 0px 0px 2px #00000033",
            }}
          >
            <p
              style={{ fontSize: "18px", fontWeight: "400", color: "#718096" }}
            >
              Pelamar Belum Dinilai
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1 style={{ margin: "0", color: "#2A4365", fontSize: "40px" }}>
                {notAssessed}
              </h1>
              <div>
                <img src={jumlahFeedback} alt="jumlahFeedback" />
              </div>
            </div>
            <hr />
            <span style={{ fontSize: "16px", fontWeight: "700", color: "#319795", cursor: "pointer"}} onClick={lihatSelengkapPelamar}>
              Lihat selengkapnya ...
            </span>
          </Col>
        </Row>

        <Row>
          {/* ------- RIWAYAT PELAMAR ------- */}
          <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "22px",
                  margin: "0",
                  color: "#2A4365",
                }}
              >
                Riwayat Pelamar
              </p>
            </div>
            <div className="table-responsive">
              {pelamar.length !== 0 ? (
                <>
                  <Table className="align-middle table-nowrap mb-0">
                    <thead
                      style={{ backgroundColor: "#FED7D7", color: "#E53E3E" }}
                    >
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Nama</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pelamar.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">{1 + index}</th>
                          <td>{crypto.aesDecryptIdentitas(item.nama)}</td>
                          {item.statusNilai === "selesai" && (
                            <td className="status">
                              <span
                                style={{
                                  width: "100px",
                                  color: "#38A169",
                                  padding: "5px 15px",
                                  borderRadius: "5px",
                                  backgroundColor: "#f0fff4",
                                  fontWeight: "600",
                                }}
                              >
                                Telah Dinilai
                              </span>
                            </td>
                          )}
                      {item.statusNilai === null && item.aspek_Nilai_Amanah === null || item.aspek_Komentar_Amanah === null || item.aspek_Nilai_Kompeten === null || item.aspek_Komentar_Kompeten === null || item.aspek_Nilai_Harmonis === null || item.aspek_Komentar_Harmonis === null || item.aspek_Nilai_Loyal === null || item.aspek_Komentar_Loyal === null || item.aspek_Nilai_Adaptif === null || item.aspek_Komentar_Adaptif === null || item.aspek_Nilai_Kolaboratif === null || item.aspek_Komentar_Kolaboratif === null || item.appearance_Nilai_Impact === null || item.appearance_Komentar_Impact === null || item.appearance_Nilai_OralCom === null ||  item.appearance_Komentar_OralCom === null ? ( // eslint-disable-line
                        <>
                        {item.statusNilai === null && (
                            <td className="status">
                              <span
                                style={{
                                  width: "100px",
                                  color: "#D69E2E",
                                  padding: "5px 15px",
                                  borderRadius: "5px",
                                  backgroundColor: "#FEFCBF",
                                  fontWeight: "600",
                                }}
                              >
                                Belum Dinilai
                              </span>
                            </td>
                          )}
                        </>
                      ) : (
                        <>
                          {item.statusNilai === null && (
                            <td className="status">
                            <span
                              style={{
                                width: "100px",
                                color: "#4299E1",
                                padding: "5px 15px",
                                borderRadius: "5px",
                                backgroundColor: "#EBF8FF",
                                fontWeight: "600",
                              }}
                            >
                              Draft
                            </span>
                          </td>
                          )}
                        </>
                      )}

                          <td>
                            {" "}
                            <Button
                              color="info"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                              onClick={() => {
                                getPesertaByIdDetail(item.id);
                                openModalDetailPelamar();
                              }}
                            >
                              <i className="ri-eye-fill"></i> Lihat
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <>
                  <div
                    style={{
                      textAlign: "center",
                      border: "1px solid #FC8181",
                      padding: "40px 10px",
                      borderRadius: "10px",
                      fontSize: "18px",
                      fontWeight: "700",
                      color: "#2A4365",
                    }}
                  >
                    Not Found
                  </div>
                </>
              )}
            </div>
          </Col>

          {/* ------- CALENDER ------- */}
          <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontWeight: "600", fontSize: "22px", margin: "0" }}>
                Agenda
              </p> 
              <Button
                style={{
                  padding: "0.5rem",
                  width: "200px",
                  height: "40px",
                  fontWeight: "700",
                  backgroundColor: "white",
                  color: "#319795",
                  border: "none",
                }}
                onClick={() => {
                  openModalAgenda();
                  getDateNow();
                }}
              >
                Lihat selengkapnya →
              </Button>
              {/* <a style={{ fontSize: '16px', fontWeight: '700', color: '#319795' ,  cursor: 'pointer' }} onClick={() => {openModalAgenda()}}>Lihat selengkapnya →</a> */}
            </div>
            {/* <FullCalendar
              plugins={[ dayGridPlugin ]}
              initialView="dayGridMonth"
              weekends={false}
              events={[
                { title: 'event 1', date: '2023-01-01',color: '#319795' },
                { title: 'event 2', date: '2023-02-02' ,color: '#319795'}
              ]}
            /> */}
            <FullCalendar
              //initialView="dayGridMont"
              // defaultView ="dayGridMount",
              style={{ border: "1px solid #FC8181", borderRadius: "10px" }}
              plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
              themeSystem="bootstrap"
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: "",
              }}
              events={[
                {
                  title: "test",
                  date: "2023-01-12",
                },
                {
                  title: "apa",
                  date: "2023-01-14",
                },
              ]}
              editable={true}
              droppable={true}
              selectable={true}
              //  dateClick={handleDateClick}
              //   eventClick={handleEventClick}
              //  drop={onDrop}
            />
          </Col>
        </Row>
      </div>

      {/* ---------- MODAL DETAIL PELAMAR ----------- */}
      <Modal
        size="sm"
        isOpen={modalDetailPelamar}
        toggle={() => {
          openModalDetailPelamar();
        }}
        centered
      >
        <ModalHeader
          className="modal-title"
          id="myModalLabel"
          toggle={() => {
            openModalDetailPelamar();
          }}
        >
          <p
            style={{
              margin: "0px",
              color: "#2A4365",
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            Detail Pelamar
          </p>
        </ModalHeader>

        <ModalBody>
          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Nama
          </p>
          <p style={{ fontWeight: "500", color: "#718096" }}>{namato}</p>

          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Email
          </p>
          <p style={{ fontWeight: "500", color: "#718096" }}>{emailto}</p>

          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            No HP
          </p>
          
          {noHPto==="" ?
          <p style={{ fontWeight: "500", color: "#718096" }}>---</p> :

          <p style={{ fontWeight: "500", color: "#718096" }}>{noHPto}</p>
          }

          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Alamat
          </p>
          {alamatto ===""? (  
          <p style={{ fontWeight: "500", color: "#718096" }}>---</p> ): 
          (   <p style={{ fontWeight: "500", color: "#718096" }}>{alamatto}</p>)}
          <p
            style={{
              fontWeight: "700",
              color: "#4A5568",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Tanggal Lahir
          </p>
          {tanggalLahirto ===""? (  
          <p style={{ fontWeight: "500", color: "#718096" }}>---</p> ): 
          (   <p style={{ fontWeight: "500", color: "#718096" }}>{tanggalLahirto}</p>)}
        </ModalBody>
      </Modal>

      {/* ---------- MODAL DETAIL AGENDA ----------- */}
      <Modal
        size="md"
        isOpen={modalAgenda}
        toggle={() => {
          openModalAgenda();
        }}
        centered
      >
        <ModalHeader
          className="modal-title"
          id="myModalLabel"
          toggle={() => {
            openModalAgenda();
          }}
        >
          <p
            style={{
              margin: "0px",
              color: "#2A4365",
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            Agenda
          </p>
        </ModalHeader>

        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "auto",
            height: "380px",
          }}
        >
           <Alert
              hidden={errorAdd}
              color="warning"
              style={{
                textAlign: 'center',
                backgroundColor: '#FFF3B5',
                color: '#D88E00',
                borderColor: '#FFF3B5',
                margin: '10px 0px',
                transition: '0.5s'
              }}
            >
              Something wrong, please check again
            </Alert>
          <Alert
            hidden={errorTanggal}
            color="warning"
            style={{
              textAlign: "center",
              backgroundColor: "#FFF3B5",
              color: "#D88E00",
              borderColor: "#FFF3B5",
              margin: "10px 0px",
              transition: "0.5s",
            }}
          >
            Tanggal Berakhir tidak boleh kurang dari Tanggal Mulai
          </Alert>
          <Row style={{ alignItems: "center", width: '100%' }}>
            
            
              <div>
                <select
                  className="form-select"
                  //id="deatType"
                  data-choices
                  aria-label="Default select example"
                  required
                  // className="mb-0"
                  id="fruits"
                  Value={select}
                  onChange={(e) => setSelect(e.target.value)}
                >
                  <option
                    value=""
                    // data-custom-properties="[object Object]"
                  >
                    Pilih Rentang Waktu
                  </option>
                  <option value="Month">Bulan Ini</option>
                  <option value="Year">Tahun Ini</option>
                  <option value="Time">Pilih Rentang Waktu</option>
                </select>
              </div>
           
            {select === "Time" ? (
              <>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} style={{ marginBottom: "15px", marginTop: '25px' }}>
                  <div>
                    <Label htmlFor="mulai" className="form-label">
                      Tanggal Mulai
                    </Label>
                    <Input
                      className="form-control" //placeholder="YYYY-MM-DD"
                      type="date"
                      value={tanggalMulai}
                      onChange={changeTanggalMulai}
                    />
                    {errorTanggalMulai && (
                      <p
                        style={{
                          margin: "0",
                          color: "#F56565",
                          fontSize: "10px",
                          position: "absolute",
                        }}
                      >
                        {errorTanggalMulai}
                      </p>
                    )}
                  </div>
                </Col>

                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ marginBottom: "15px", marginTop: '25px' }}
                >
                  <div>
                    <Label htmlFor="berakhir" className="form-label">
                      Tanggal Berakhir
                    </Label>
                    <Input
                      type="date"
                      value={tanggalBerakhir}
                      onChange={changeTanggalBerakhir}
                      className="form-control"
                    />
                    {errorTanggalBerakhir && (
                      <p
                        style={{
                          margin: "0",
                          color: "#F56565",
                          fontSize: "10px",
                          position: "absolute",
                        }}
                      >
                        {errorTanggalBerakhir}
                      </p>
                    )}
                  </div>
                </Col>
                <Col>
                  <div >
                    <div style={{ textAlign: "center" }}>  
                    <Button
                   
                      className=" w-100 btn btn-success"
                      color="success"
                      centered
                      onClick={() => {
                        selectEvent();
                      }}
                    >
                      Pilih
                    </Button>
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                    {eventOnDate.length !== 0 ? (
                      <>
                        <hr />
                        {eventOnDate.map((item) => (
                          <>
                            <div style={{ marginTop: "5px" }}>
                              <p>{item.judul_Interview}</p>
                              <p className="text-muted">
                                {item.tanggal} {item.bulanMulaiName}{" "}
                                {item.tahun} - {item.tanggalAkhir}{" "}
                                {item.bulanAkhirName} {item.tahunAkhir}
                              </p>
                            </div>
                          </>
                        ))}
                      </>
                    ):(
                      <div style={{ marginTop: "5px" }}>
                 
                     <hr />
                     <br />
                     <br />
                   

                     <div style={{ marginTop: "10px" }}>
                       <p className="text-muted"> {message} </p>
                       
                       </div>

                    </div>)}
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              <div style={{ marginLeft: "5px" }}>
                <hr />

                {eventList.map((item) => (
                  <>
                    {select === "Month" && (item.bulan === month  || (item.bulanAkhir >=  month && item.bulan <= month)) &&(
                      <div style={{ marginTop: "5px" }}>
                        <p>{item.judul_Interview}</p>
                        <p className="text-muted">
                          {item.tanggal} {item.bulanMulaiName} {item.tahun}-{" "}
                          {item.tanggalAkhir} {item.bulanAkhirName}{" "}
                          {item.tahunAkhir}
                        </p>
                      </div>
                    )}

                    {select === "Year" && (item.tahun === year || (item.tahunAkhir >= year && item.tahun <= year) )&& (
                      <>
                        <p>{item.judul_Interview}</p>
                        <p className="text-muted">
                          {item.tanggal} {item.bulanMulaiName} {item.tahun}-{" "}
                          {item.tanggalAkhir} {item.bulanAkhirName}{" "}
                          {item.tahunAkhir}
                        </p>
                      </>
                    )}
                  </>
                ))}
              </div>
            )}
          </Row>
        </ModalBody>
      </Modal>

      {/* ---------- MODAL JWT -------- */}
      <Modal isOpen={modalJWT} centered >
        <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={Timeout} />
          <h5 style={{ textAlign: 'center', marginTop: '30px', fontSize: '24px', fontWeight: '700', color: '#2A4365' }}>Maaf, Silahkan Login Kembali</h5>
          <p style={{ padding: '0 45px', textAlign: 'center', fontSize: '16px', color: '#4A5568', fontWeight: '400' }}>Sesi Anda sudah kadaluarsa, Jangan khawatir... Click tombol di bawah ini untuk login kembali :)</p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center' }}>
          <Button style={{ padding: '0.5rem 3.0rem' }} className="btn btn-success" onClick={() => { window.localStorage.clear(); window.sessionStorage.clear(); window.location.reload(); window.location.href = "/login-admin"; }} >Login</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default I1;
