import React, { useState } from "react";
import Webcam from "react-webcam";
import {
  Col,
  Container,
  Row,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import FotoModal from "../../assets/images/telkom/modal.png";

const P3 = () => {
  document.title = "Dashboard | Velzon - React Admin & Dashboard Template";

  // -------- WEBCAM -------- //
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);
  // ~~~~~~ WEBCAM ~~~~~~ //

  // ---------- ON NEXT --------- //
  const [step, setStep] = React.useState(0);

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  // ~~~~~~~~ ON NEXT ~~~~~~~~~ //

  // --------- MODAL ---------- //
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {step === 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Col xxl={4} md={4}>
                <div
                  style={{
                    color: "#8C8C8C",
                    backgroundColor: "#E8E8E8",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                    margin: "0",
                  }}
                >
                  <h3
                    style={{
                      margin: "0",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Trial Jawaban
                  </h3>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    height: "200px",
                    border: "3px dashed #e5e5e5",
                    padding: "20px",
                  }}
                >
                  <h5>Apa kelebihan dan kekurangan anda coba jelaskan ?</h5>
                </div>
              </Col>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {capturing ? (
                  <>
                    <Webcam
                      width="480"
                      height="360"
                      audio={true}
                      ref={webcamRef}
                    />
                    <button onClick={handleStopCaptureClick}>
                      Stop Capture
                    </button>
                  </>
                ) : (
                  <>
                    <Webcam
                      width="480"
                      height="360"
                      audio={true}
                      ref={webcamRef}
                    />
                    <button onClick={handleStartCaptureClick}>
                      Start Capture
                    </button>
                  </>
                )}
                {recordedChunks.length > 0 && (
                  <button onClick={handleDownload}>Download</button>
                )}
              </div>
            </div>
            <Button
              style={{ width: "120px", float: "right" }}
              color="success"
              onClick={onNext}
            >
              Next
            </Button>
          </>
        )}

        {step === 1 && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Col xxl={4} md={4}>
                <div
                  style={{
                    color: "#8C8C8C",
                    backgroundColor: "#E8E8E8",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "100%",
                    margin: "0",
                  }}
                >
                  <h3
                    style={{
                      margin: "0",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Trial Jawaban
                  </h3>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    height: "200px",
                    border: "3px dashed #e5e5e5",
                    padding: "20px",
                  }}
                >
                  <h5>Apa kelebihan dan kekurangan anda coba jelaskan ?</h5>
                </div>
              </Col>
              <Col xxl={6} md={6}>
                <div className="mb-3">
                  <textarea
                    style={{
                      border: "none",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)",
                      borderRadius: "12px",
                      padding: "40px 30px",
                    }}
                    className="form-control"
                    placeholder="Ketik Jawaban Anda"
                    rows="10"
                    required
                  />
                </div>
              </Col>
            </div>
            <Button
              style={{ width: "120px", float: "right" }}
              color="success"
              onClick={() => tog_list()}
            >
              Next
            </Button>
          </>
        )}
      </div>
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
      >
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={FotoModal} />
          <h5 style={{ textAlign: "center", marginTop: "30px" }}>
            Apakah Anda Sudah Siap?
          </h5>
          <p style={{ padding: "0 70px", textAlign: "center" }}>
            Pastikan kembali perangkat yang Anda gunakan terhubung dengan
            jaringan internet.
          </p>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-evenly" }}>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => setmodal_list(false)}
          >
            Close
          </button>
          <button type="button" className="btn btn-success" id="edit-btn">
            Mulai
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default P3;
