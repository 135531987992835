import React, { useState } from "react";
import Webcam from 'react-webcam';
import { Col, Container, Row , Button, Input, Modal, ModalBody, ModalFooter,} from "reactstrap";
import FotoModal from '../../assets/images/telkom/modal.png';


const P3 = () => {
  document.title = "Dashboard | Velzon - React Admin & Dashboard Template";


  // ---------- ON NEXT --------- //
  const [step, setStep] = React.useState(0);

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  // ~~~~~~~~ ON NEXT ~~~~~~~~~ //

  // --------- MODAL ---------- //
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
      setmodal_list(!modal_list);
  }



  return (
    <React.Fragment>
      <div className="page-content">


      </div>
      <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
        <ModalBody style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={FotoModal} />
          <h5 style={{textAlign: 'center', marginTop: '30px'}}>Apakah Anda Sudah Siap?</h5>
          <p style={{padding: '0 70px', textAlign: 'center'}}>Pastikan kembali perangkat yang Anda gunakan terhubung dengan jaringan internet.</p>
        </ModalBody>
        <ModalFooter style={{justifyContent: 'space-evenly'}}>
            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
            <button type="button" className="btn btn-success" id="edit-btn">Mulai</button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default P3;
